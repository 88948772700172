import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MonthlyPaymentChart from './chart';
import '../styles/statistics.css'
import { connect } from 'react-redux';
import { getStatistics } from "../../../actions/statistics"
import { LoadingOutlined } from '@ant-design/icons';


class Statistics extends Component {


    componentDidMount() {
        (this.props).getStatistics()
    }


    render() {
        return (
            <div className="gateway-statistics">
                <h1 className="title-style">آمار و ارقام پرداخت ها</h1>
                <div className="sized-box-height-40"></div>
                {
                    (this.props).statistics.is_loading ? <LoadingOutlined></LoadingOutlined>
                        : <MonthlyPaymentChart details={(this.props).statistics.data}></MonthlyPaymentChart>
                }

            </div>
        );
    }
}


const mapPropsToState = (props) => {
    return {
        statistics: props.statistics
    }
}


export default connect(mapPropsToState, { getStatistics })(Statistics);