import { message } from "antd"
import { AuthRequester } from "../globals/configs"
import { CardManagementTypes } from "../globals/types"






export const addNewCardPayment = (card_number, month, year) => dispatch => {
    dispatch({
        type: CardManagementTypes.UPDATE_STATE,
        data: {},
        is_loading: true
    })
    AuthRequester.post("/vendor/addPaymentMethod", {
        card_number: card_number,
        card_exp_month: month,
        card_exp_year: year
    })
        .then((res) => {
            dispatch({
                type: CardManagementTypes.UPDATE_STATE,
                data: res.data.data,
                is_loading: false
            })
        })
        .catch(err => {
            dispatch({
                type: CardManagementTypes.UPDATE_STATE,
                data: null,
                is_loading: false
            })
            console.log(err);
            message.error({
                content: "مشکلی هنگام بروزرسانی کارت ها بوجود آمده است. لطفا مجددا تلاش کنید."
            })
        })
}