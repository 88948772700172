import { HomeTypes } from "../globals/types"



var serversState = {
    servers: [],
    is_loading: false
}


export const getListOfServers = (state = serversState, action) => {
    switch (action.type) {
        case HomeTypes.DATA_PROGRESS:
            return {
                ...state,
                servers: action.servers,
                is_loading: action.is_loading
            }
        case HomeTypes.DATA_STATE:
            return {
                ...state,
                servers: action.servers,
                is_loading: action.is_loading
            }
        default: 
            return {
                ...state
            }
    }
}