



import { useEffect } from 'react';
import { useState } from 'react';
import '../../widgets/styles/card.css'


const CardComponent = (props) => {

    useEffect(() => {
        initState();
    }, [])



    let [first, setFirst] = useState("0000")
    let [second, setSecond] = useState("0000")
    let [third, setThird] = useState("0000")
    let [fourth, setFourth] = useState("0000")
    let [expMonth, setExpMonth] = useState("00")
    let [expYear, setExpYear] = useState("00")


    const initState = () => {
        if (Object.keys(props.query.card).length > 0) {
            let _cardNumber = (props.query.card.card_number).match(/.{1,4}/g);
            setFirst(_cardNumber[0])
            setSecond(_cardNumber[1])
            setThird(_cardNumber[2])
            setFourth(_cardNumber[3]);
            setExpMonth(props.query.card.card_exp_month);
            setExpYear(props.query.card.card_exp_year);
        }
    }


    const handleInputs = (e: any) => {
        var target = e.srcElement || e.target;
        let _id = target.id;

        switch (_id) {
            case "first":
                setFirst(target.value);
                break;
            case "second":
                setSecond(target.value);
                break;

            case "third":
                setThird(target.value);
                break;
            case "fourth":
                setFourth(target.value);
                break;
            case "month":
                setExpMonth(target.value);
                break;
            case "year":
                setExpYear(target.value);
                break;
        }

        var maxLength = parseInt(target.attributes["maxlength"].value, 10);
        var myLength = target.value.length;
        if (props.onCardUpdate !== undefined) {
            props.onCardUpdate(first, second, third, fourth, expMonth, expYear);
        }

        if (myLength >= maxLength) {
            var next = target;

            while (next = next.nextElementSibling) {
                if (next == null)
                    break;
                if (next.tagName.toLowerCase() === "input") {
                    next.focus();
                    break;
                }
            }
        }
        // Move to previous field if empty (user pressed backspace)
        else if (myLength === 0) {
            var previous = target;
            while (previous = previous.previousElementSibling) {
                if (previous == null)
                    break;
                if (previous.tagName.toLowerCase() === "input") {
                    previous.focus();
                    break;
                }
            }
        }
    }




    let ExpCVC = (
        <div className="exp-cvc">
            <div className="sized-box-height-10"></div>
            {/* <h1 className="title-style">شماره شناسایی دوم (CVV2):</h1> */}
            {/* <p className="title-low-sized">شماره CVV 2 درج شده روی کارت را وارد نمایید</p>
            <div className="sized-box-height-10"></div>
            <div className="cvc-handler" onKeyUp={handleInputs}>
                <input className="input-style card-item" type="text" placeholder="CVV2" maxLength={4}></input>
            </div> */}
            <div className="sized-box-height-10"></div>
            <h1 className="title-style">تاریخ انقضا کارت:</h1>
            <p className="title-low-sized">ماه و سال تاریخ انقضای کارت را وارد کنید</p>
            <div className="sized-box-height-10"></div>
            <div className="exp-handler" onKeyUp={handleInputs}>
                <input className="input-style card-item" id="month" type="text" placeholder={expMonth || "ماه"} maxLength={2}></input>
                <div className="sized-box-width-10"></div>
                <input className="input-style card-item" id="year" type="text" placeholder={expYear || "سال"} maxLength={2} ></input>
            </div >
            {/* <div className="sized-box-height-10"></div>
            <h1 className="title-style">رمز دوم اینترنتی کارت:</h1>
            <p className="title-low-sized">رمز دوم خود را وارد کنید</p>
            <div className="sized-box-height-10"></div>
            <div className="exp-handler" onKeyUp={handleInputs}>
                <button className="button-element">دریافت رمز دوم</button>
                <div className="sized-box-width-10"></div>
                <input className="input-style card-item" type="password" placeholder="رمز دوم" maxLength={10}></input>
            </div> */}
        </div >
    )


    return (
        <div className={"card-element" + (props.validation.is_valid === true ? "" : " card-invalid")}>
            <div style={{ width: "100%", }}>
                <div className="sorter">
                    <div className="card-logo"></div>
                </div>
                <div className="row-detail">
                    <p className="title-style bold">شماره کارت</p>
                    {props.validation.is_valid ? <p className="title-low-sized bank-name">{props.validation.persianName}</p> : <div></div>}
                </div>
                <p className="title-low-sized">شماره ۱۶ رقمی درج شده روی کارت را وارد کنید</p>
                <div className="sized-box-height-10"></div>
            </div>
            <div className="input-handler" id="card-element-inputs" onKeyUp={handleInputs}>
                <input className="input-style card-item" id="first" type="text" placeholder={first || "0000"} maxLength={4}></input>
                <div className="sized-box-width-10"></div>
                <input className="input-style card-item" id="second" type="text" placeholder={second || "0000"} maxLength={4}></input>
                <div className="sized-box-width-10"></div>
                <input className="input-style card-item" id="third" type="text" placeholder={third || "0000"} maxLength={4}></input>
                <div className="sized-box-width-10"></div>
                <input className="input-style card-item" id="fourth" type="text" placeholder={fourth || "0000"} maxLength={4} max={4}></input>
                <div className="sized-box-width-20"></div>
            </div>
            {props.withExtra ? ExpCVC : null}
            <div className="sized-box-height-20"></div>
            <button className="button-element" disabled={props.isLoading} onClick={(e) => props.cardDone(first, second, third, fourth, expMonth, expYear)} style={{ width: "100%" }}>{Object.keys(props.query.card).length > 0 ? "بروزرسانی کارت" : "ثبت کارت"}</button>

        </div>
    )
}
export default CardComponent;