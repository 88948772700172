import logo from './logo.svg';
import './App.css';
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import LoginView from './views/login/login';
import HomeView from './views/home/home';
import 'antd/dist/antd.css';
import UsersView from './views/users/users';
import TimeAgo from 'javascript-time-ago'
import React, { Component } from 'react';
import fa from 'javascript-time-ago/locale/fa'

TimeAgo.addDefaultLocale(fa)
TimeAgo.addLocale(fa)

class App extends Component {


  componentDidMount() {
    this.checkRouteAuth()
  }

  componentDidUpdate(prevProps, prevState) {
    this.checkRouteAuth()
  }



  checkRouteAuth = () => {
    var currentLocation = window.location.pathname
    var accessToken = localStorage.getItem("_access_token");
    if (!accessToken && currentLocation != "/") {
      window.location = "/";
    }
  }

  render() {
    return (
      <Router >
        <div className="App">
          <Switch >
            <Route path="/" exact >
              <LoginView />
            </Route>
            <Route path="/home" exact>
              <HomeView />
            </Route>
            <Route path="/users" exact>
              <UsersView />
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
