import { message } from "antd"
import { AuthRequester } from "../globals/configs"
import { TransactionTypes } from "../globals/types"
import { ErrorHandler } from "../utils/error_handler"


var initialData = {
    is_loading: false,
    data: []
}

export const getTransactions = (page) => dispatch => {
    if (page === 0) {
        initialData.data = []
    }
    dispatch({
        type: TransactionTypes.GET_TRANSACTIONS,
        // prevent triggering loading for more data loader
        is_loading: page > 0 ? false : true,
        has_more: true,
        data: initialData.data
    })

    AuthRequester.post("/transactions/getTransactions?page=" + page,)
        .then(res => {
            console.log(res.data);
            initialData.data.push(...res.data.data)
            dispatch({
                type: TransactionTypes.GET_TRANSACTIONS,
                is_loading: false,
                has_more: res.data.data.length == 0 ? false : true,
                data: initialData.data
            })
        })
        .catch(err => {
            message.error("خطایی هنگام دریافت لیست تراکنش ها بوجود آمده است، لطفا مجددا تلاش نمایید.")
            dispatch({
                type: TransactionTypes.GET_TRANSACTIONS,
                is_loading: false,
                has_more: false,
                data: initialData.data
            })
        })
}