import { act } from "react-dom/test-utils";
import { TransactionTypes } from "../globals/types"

var initState = {
    is_loading: false,
    data: null
}

export const transactionReducer = (state = initState, action) => {
    switch (action.type) {
        case TransactionTypes.GET_TRANSACTIONS:
            return {
                ...state,
                is_loading: action.is_loading,
                has_more: action.has_more,
                data: action.data
            }
        case TransactionTypes.GET_TRANSACTIONS_DONE:
            return {
                ...state,
                is_loading: action.is_loading,
                has_more: action.has_more,
                data: action.data
            }
        default:
            return { ...state }
    }
}