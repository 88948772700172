import { message, notification } from "antd";
import { AuthRequester, intializeAuthRequester, Requester } from "../globals/configs";
import { LoginStates } from "../globals/types"
import { ErrorHandler } from "../utils/error_handler";


var userCredentials = {
    user: {},
    is_logged_in: false
}

export const loginWithApi = (props, username, password) => async dispatch => {
    dispatch({
        type: LoginStates.LOGIN_PROGRESS,
        data: {},
        is_loading: true,
        is_logged_in: false
    });

    const res = await Requester.post("/authentication/login", {
        username: username,
        password: password
    })
        .then(async res => {
            await localStorage.setItem("_access_token", res.data.data.access)
            await localStorage.setItem("__USSR", JSON.stringify(res.data.data.profile))
            intializeAuthRequester()
            setTimeout(() => {
                dispatch({
                    type: LoginStates.LOGIN_PROGRESS,
                    data: {},
                    user: res.data.data.profile,
                    is_loading: false,
                    is_logged_in: true
                });
                props.history.push("/home")
            }, 2000)
        })
        .catch(err => {
            console.log(err);
            message.error(
                "خطا هنگام ورود، لطفا ایمیل و رمز عبور را بررسی نموده مجددا تلاش نمایید"
            );
            dispatch({
                type: LoginStates.LOGIN_PROGRESS,
                data: {},
                is_loading: false,
                is_logged_in: false
            });
        });
}


export const getLoginState = () => dispatch => {
    try {
        dispatch({
            type: LoginStates.LOGIN_STATE,
            data: JSON.parse(localStorage.getItem("__USSR")),
            user: JSON.parse(localStorage.getItem("__USSR")),
            is_loading: false,
            is_logged_in: userCredentials.is_logged_in
        })
        AuthRequester.get('/vendor')
            .then(res => {

                dispatch({
                    type: LoginStates.LOGIN_STATE,
                    data: res.data.data,
                    user: res.data.data,
                    is_loading: false,
                    is_logged_in: userCredentials.is_logged_in
                })
            })
            .catch(err => {
            })
    } catch (error) {
        console.log(error);
        dispatch({
            type: LoginStates.LOGIN_STATE,
            data: {},
            user: {},
            is_loading: false,
            is_logged_in: userCredentials.is_logged_in
        })
    }

}