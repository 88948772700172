import { act } from "react-dom/test-utils";
import { SearchTypes } from "../globals/types"

var initState = {
    is_loading: false,
    data: null
}

export const searchReducer = (state = initState, action) => {
    switch (action.type) {
        case SearchTypes.SEARCH_IN_PROGRESS:
            return {
                ...state,
                is_loading: action.is_loading,
                data: action.data
            }
        case SearchTypes.SEARCH_STATE:
            return {
                ...state,
                is_loading: action.is_loading,
                data: action.data
            }
        default:
            return {...state}
    }
}