import { AuthRequester } from "../globals/configs";
import { StatisticsTypes } from "../globals/types"






export const getStatistics = () => dispatch => {
    dispatch({
        type: StatisticsTypes.UPDATE_STATE,
        data: null,
        is_loading: true
    });
    AuthRequester.get('/vendor/statistics')
        .then(res => {
            dispatch({
                type: StatisticsTypes.UPDATE_STATE,
                data: res.data.data,
                is_loading: false
            });
        })
        .catch(err => {
            console.log(err);
            dispatch({
                type: StatisticsTypes.UPDATE_STATE,
                data: null,
                is_loading: false
            });
        })
}