import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getTransactions } from "../../../actions/transactions"
import '../styles/transactions.css'
import NumberFormat from 'react-number-format';
import { LoadingOutlined } from '@ant-design/icons';
import ReactTimeAgo from 'react-time-ago'
import InfiniteScroll from "react-infinite-scroll-component";









class TransactionsView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0
        }
    }


    componentDidMount() {
        document.getElementById('content-scrollbar').style.overflowY = "hidden"
        // document.getElementById('transactions').style.overflowY = "scroll !important"
        this.props.getTransactions(this.state.page)
    }

    componentWillUnmount() {
        document.getElementById('content-scrollbar').style.overflowY = "scroll"
        // document.getElementById('transactions').style.overflowY = "scroll !important"
    }



    onNextPageCalled = () => {
        this.state.page++;
        this.props.getTransactions(this.state.page)
    }


    mapStatusToText = (status) => {
        switch (status) {
            case 'pending':
                return 'تکمیل نشده';
            case 'completed':
                return 'انجام شده';
            case 'expired':
                return 'منقضی شده';
            default:
                return 'نامشخص'
        }
    }


    render() {
        return (
            <div className="transactions-wrapper">
                <h1 className="title-style">لیست تراکنش ها</h1>
                <p className="title-low-sized">لیست تراکنش هایی که از طریق پلتفرم شما درخواست شده است</p>
                <div className="sized-box-height-40"></div>
                <div className="transaction-viewer" id="transactions">
                    <InfiniteScroll
                        className="scroller-widget"
                        style={{ overflow: "visible" }}
                        dataLength={this.props.transaction.data !== null ? this.props.transaction.data.length : 0}
                        next={this.onNextPageCalled}
                        hasMore={this.props.transaction.has_more}
                        pullDownToRefresh
                        refreshFunction={this.onNextPageCalled}
                        scrollableTarget="transactions"
                        loader={<LoadingOutlined></LoadingOutlined>}
                    >
                        {this.props.transaction.is_loading ?
                            <LoadingOutlined></LoadingOutlined> :
                            (this.props.transaction.data || []).map((transaction) => {
                                return <div className="transaction-item">
                                    <div className="transaction-title">
                                        <NumberFormat value={transaction.total_amount} displayType="text" thousandSeparator suffix="ریال" className="title-style bold"></NumberFormat>
                                        <h1 className={"title-low-sized bold " + transaction.transaction_status} style={{ opacity: .5 }}>وضعیت: {this.mapStatusToText(transaction.transaction_status)}</h1>
                                    </div>
                                    <div className="transaction-title">
                                        <h1 className="title-low-sized bold">{transaction.transaction_number}</h1>
                                        <ReactTimeAgo className="title-low-sized bold" style={{ opacity: .5 }} date={transaction.updatedAt} locale="en-US" />
                                    </div>
                                </div>
                            })
                        }
                        <div className="sized-box-height-40 mobile-padding"></div>
                        <div className="sized-box-height-40 mobile-padding"></div>
                    </InfiniteScroll>
                </div>
            </div>
        );
    }
}

const mapPropsToState = (props) => {
    return {
        transaction: props.transaction
    }
}

export default connect(mapPropsToState, { getTransactions })(TransactionsView);