
export const LoginStates = {
    LOGIN_PROGRESS: "LOGIN_PROGRESS",
    LOGIN_STATE: "LOGIN_STATE"
}

export const HomeTypes = {
    DATA_PROGRESS: "DATA_PROGRESS",
    DATA_STATE: "DATA_STATE"
}

export const UsersTypes = {
    DATA_PROGRESS: "USERS_DATA_PROGRESS",
    DATA_STATE: "USERS_DATA_STATE"
}


export const SearchTypes = {
    SEARCH_IN_PROGRESS: "SEARCH_IN_PROGRESS",
    SEARCH_STATE: "SEARCH_STATE"
}

export const ChangePasswordTypes = {
    OPERATION_IN_PROGRESS: "OPERATION_IN_PROGRESS",
    OPERATION_DONE: "OPERATION_DONE"
}


export const StatisticsTypes = {
    UPDATE_STATE: "UPDATE_STATE"
}


export const CardManagementTypes = {
    UPDATE_STATE: "UPDATE_STATE"
}


export const TransactionTypes = {
    GET_TRANSACTIONS: "GET_TRANSACTIONS",
    GET_TRANSACTIONS_DONE: "GET_TRANSACTIONS_DONE"
}

export const SettingsTypes = {
    UPDATE_STATE: "UPDATE_STATE"
}

export const ServerTypes = {
    CREATE_SERVER: "CREATE_SERVER",
    UPDATE_SERVER: "UPDATE_SERVER",
    CURRENT_STATE: "CURRENT_STATE"
}