import { message } from "antd";
import { AuthRequester } from "../globals/configs";
import { HomeTypes } from "../globals/types"
import { ErrorHandler } from "../utils/error_handler"



export const getServerList = () => async dispatch => {
    dispatch({
        type: HomeTypes.DATA_PROGRESS,
        servers: [],
        is_loading: true
    });

    await AuthRequester.get("/admins/servers")
        .then((res) => {
            dispatch({
                type: HomeTypes.DATA_PROGRESS,
                servers: res.data.data,
                is_loading: false
            });
        })
        .catch(err => {
            if (err.response) {
                var error = new ErrorHandler().hasError(err.response.data);
                if (error.hasError) {
                    message.error(error.message['errorMessage'])
                }
            }else {
                message.error("Connection Failed!")
            }
            dispatch({
                type: HomeTypes.DATA_PROGRESS,
                servers: [],
                is_loading: false
            });
        })

}


export const getCurrentState = () => dispatch => {

}