import { message } from "antd";
import { AuthRequester } from "../globals/configs"
import { SettingsTypes } from "../globals/types";
import { getLoginState } from "./login";






export const updateGatewaySettings = (name, redirect) => dispatch => {
    dispatch({
        type: SettingsTypes.UPDATE_STATE,
        is_loading: true,
        data: null
    })

    AuthRequester.post("/vendor/changePublicDetails", {
        business_name: name,
        redirect_url: redirect
    }).then((res) => {
        message.success({
            content: 'اطلاعات درگاه با موفقیت بروزرسانی شد!'
        })
        dispatch(getLoginState())
        dispatch({
            type: SettingsTypes.UPDATE_STATE,
            is_loading: false,
            data: res.data.data
        })
    })
        .catch(err => {
            console.log(err);
            message.error({
                content: 'خطایی هنگام بروزرسانی اطلاعات درگاه بوجود آمده است، لطفا مجددا تلاش نمایید'
            })
            dispatch({
                type: SettingsTypes.UPDATE_STATE,
                is_loading: false,
                data: null
            })
        })
}