import { Component } from 'react';
import { getLoginState, loginWithApi } from "../../actions/login"

// Styles 
import "./styles/login.css";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class LoginView extends Component {



    constructor(props) {
        super(props)
        this.state = {
            username: '',
            password: ''
        }
    }

    componentDidMount() {
        this.props.getLoginState()
    }


    onFinish = (e, props) => {

        let form = document.getElementById("login-form")
        if (props.login.is_loading) {
            return;
        }

        if (!form.checkValidity()) {
            console.log("Not Valid");
            return;
        }
        e.preventDefault()


        if (this.state.username && this.state.password) {
            props.loginWithApi(props, this.state.username, this.state.password)
        }
    };



    render() {


        return (
            <div className="login-wrapper">
                <div className="login-credentials">
                    <h1 className="title-style">پنل مدیریت درگاه - های پی</h1>
                    <div className="input-wrapper" dir="ltr">
                        <form id="login-form">
                            <div className="sized-box-height-40"></div>
                            <input type="email" required minLength="5" maxLength="128" className="input-style rtl-align" onChange={(value) => this.state.username = value.target.value} placeholder="آدرس ایمیل"></input>
                            <div className="sized-box-height-10"></div>
                            <input type="password" minLength="6" maxLength="128" required className="input-style rtl-align" onChange={(value) => this.state.password = value.target.value} placeholder="رمز عبور"></input>
                            <div className="sized-box-height-10"></div>
                            <button className="button-element" disabled={this.props.login.is_loading} onClick={(e) => this.onFinish(e, this.props)}> ورود به درگاه</button>
                            <div className="sized-box-height-10"></div>
                        </form>
                        {/* <a className="title-low-sized bold">رمز عبور را فراموش کردید؟</a> */}
                        <div className="sized-box-height-40"></div>
                        <p className="title-low-sized">برای ساخت درگاه برای کسب و کارتان لطفا با بخش فروشات ما تماس بگیرید</p>
                    </div>
                </div>
            </div>
        );
    }
}


const mapPropsToState = (props) => {
    return {
        login: props.login
    }
}

export default withRouter(connect(mapPropsToState, { getLoginState, loginWithApi })(LoginView));