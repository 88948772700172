import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducter from "./reducers"

var initState = {}
var middlewares = [thunk]

var store = createStore(rootReducter, initState, composeWithDevTools(applyMiddleware(...middlewares)))


export default  store;