import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getCurrentState, getServerList } from "../../actions/home"
import { getLoginState } from "../../actions/login"

// Styles
import "./styles/styles.css"
import "../../globals/styles.css"
import TransactionsView from './widgets/transactions';
import GatewaySettings from './widgets/gateway_settings';
import Statistics from './widgets/statistics';
import { withRouter } from 'react-router-dom';

class HomeView extends Component {


    constructor(props) {
        super(props)
        this.state = {
            navigation_status: 0
        }
    }


    componentDidMount() {
        this.props.getLoginState()
    }



    changeMenu = (menu) => {
        this.setState({
            navigation_status: menu
        })
    }


    logout = () => {
        localStorage.removeItem('_access_token')
        localStorage.removeItem('__USSR')
        this.props.history.push("/")
    }


    render() {
        return (
            <div className="home-wrapper">
                <div className="drawer">
                    <div className="mobile-drawer-title">
                        <h1 className="title-style">مدیریت درگاه های پی </h1>
                    </div>
                    <div className="drawer-title">
                        <div className="sized-box-height-10"></div>
                        <div className="circle-avatar" style={{
                            backgroundImage: `url('${this.props.login.user.business_logo}')`
                        }}></div>
                        <div className="sized-box-height-10"></div>
                        <h1 className="title-style">{this.props.login.user.business_name} </h1>
                        <div className="sized-box-height-40"></div>
                    </div>
                    <div className="drawer-wrapper">
                        <div className={"drawer-item" + (this.state.navigation_status == 0 ? " drawer-item-focused" : "")} onClick={() => this.changeMenu(0)}>
                            <div className="icon info" ></div>
                            <div className="sized-box-width-10"></div>
                            <h1 className="title-low-sized bold">تراکنش ها</h1>
                        </div>
                        <div className={"drawer-item" + (this.state.navigation_status == 1 ? " drawer-item-focused" : "")} onClick={() => this.changeMenu(1)}>
                            <div className="icon setting" ></div>
                            <div className="sized-box-width-10"></div>
                            <h1 className="title-low-sized bold">تنظیمات درگاه</h1>
                        </div>
                        <div className={"drawer-item" + (this.state.navigation_status == 2 ? " drawer-item-focused" : "")} onClick={() => this.changeMenu(2)}>
                            <div className="icon statistics" ></div>
                            <div className="sized-box-width-10"></div>
                            <h1 className="title-low-sized bold">آمار ارقام</h1>
                        </div>
                        <div className={"drawer-item" + (this.state.navigation_status == 3 ? " drawer-item-focused" : "")} onClick={() => this.logout()}>
                            <div className="icon logout" ></div>
                            <div className="sized-box-width-10"></div>
                            <h1 className="title-low-sized bold">خروج از حساب</h1>
                        </div>
                    </div>
                </div>
                <div className="content" id="content-scrollbar">
                    <div className="sized-box-height-40"></div>
                    {
                        this.state.navigation_status == 0 ?
                            <TransactionsView></TransactionsView>
                            : this.state.navigation_status == 1 ?
                                <GatewaySettings></GatewaySettings>
                                : <Statistics></Statistics>
                    }

                </div>
            </div>
        );
    }
}

const mapPropsToState = (props) => {
    return {
        login: props.login,
    }
}

export default withRouter(connect(mapPropsToState, { getLoginState, getCurrentState })(HomeView));