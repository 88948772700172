import { Component } from 'react';
import NumberFormat from 'react-number-format';
import { Bar } from "react-chartjs-2"
import '../styles/chart.css'
import { LoadingOutlined } from '@ant-design/icons';







class MonthlyPaymentChart extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        if (!this.props.details.stats) return <LoadingOutlined></LoadingOutlined>
        console.log(this.props.details);

        const labels = this.props.details.stats.map((stats) => stats.day);
        const data = {
            labels: labels,
            datasets: [{
                label: 'فروش روزانه (ریال)',
                data: this.props.details.stats.map((stats) => stats.total_income),
                backgroundColor: [
                    'rgba(252, 152, 47, 0.3)'
                ],
                borderColor: [
                    'rgba(252, 152, 47, 1)',
                ],
                borderWidth: 1
            }, {
                label: 'تعداد فروش روزانه',
                type: 'line',
                fill: true,
                data: this.props.details.stats.map((stats) => stats.sales),
                backgroundColor: [
                    'rgba(0, 152, 47, 0.3)'
                ],
                borderColor: [
                    'rgba(252, 152, 47, 1)',
                ],
                borderWidth: 1
            }]
        };

        return (
            <div className="monthly-payment-chart">
                <div className="row-item-box">
                    <div className="row-item">
                        <h1 className="title-low-sized">تعداد تراکنش ها</h1>
                        <p className="title-style">{this.props.details.sales[0].total_sales} تراکنش</p>
                    </div>
                    <div className="row-item">
                        <h1 className="title-low-sized">مجموع درامد</h1>
                        <NumberFormat className="title-style" displayType="text" value={this.props.details.sales[0].total_income} suffix=" ریال" thousandSeparator="," ></NumberFormat>
                    </div>
                    <div className="row-item">
                        <h1 className="title-low-sized">مجموع درامد این ماه</h1>
                        <NumberFormat className="title-style" displayType="text" value={this.props.details.sales[0].total_monthly} suffix=" ریال" thousandSeparator="," ></NumberFormat>
                    </div>
                </div>
                <div className="sized-box-height-20"></div>
                <div className="chart-view">
                    <Bar data={data} options={{
                        borderRadius: 90,
                        fill: false,
                        scales: {
                            x: {
                                grid: {
                                    display: false
                                }
                            },
                            y:
                            {
                                grid: {
                                    display: false
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                title: {
                                    // This more specific font property overrides the global property
                                    font: {
                                        family: "Shabnam",
                                        size: 14
                                    }
                                },
                                labels: {
                                    // This more specific font property overrides the global property
                                    font: {
                                        family: "Shabnam",
                                        size: 14
                                    }
                                }
                            }
                        }
                    }} />
                </div>
            </div>
        );
    }
}

export default MonthlyPaymentChart;