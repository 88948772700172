import { HomeTypes, UsersTypes } from "../globals/types"



var usersState = {
    users: [],
    total_premium: 0,
    total_users: 0,
    is_loading: false,
    is_page_loading: false
}


export const getListOfUsersReducer = (state = usersState, action) => {
    switch (action.type) {
        case UsersTypes.DATA_PROGRESS:
            return {
                ...state,
                users: action.users,
                total_premium: action.total_premium,
                total_users: action.total_users,
                is_loading: action.is_loading,
                is_page_loading: action.is_page_loading
            }
        case UsersTypes.DATA_STATE:
            return {
                ...state,
                users: action.users,
                total_premium: action.total_premium,
                total_users: action.total_users,
                is_loading: action.is_loading,
                is_page_loading: action.is_page_loading
            }
        default: 
            return {
                ...state
            }
    }
}