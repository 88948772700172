import { combineReducers } from "redux";
import { changePasswordReducer } from "./change_password";
import { getListOfServers } from "./home";
import { loginReducer } from "./login";
import { transactionReducer } from "./transactions";
import { searchReducer } from "./search";
import { getListOfUsersReducer } from "./users";
import { settingsReducer } from "./settings";
import { statisticsReducer } from "./statistics";
import { cardManagementReducer } from "./card_management"


export default combineReducers({
    login: loginReducer,
    servers: getListOfServers,
    users: getListOfUsersReducer,
    search: searchReducer,
    password: changePasswordReducer,
    transaction: transactionReducer,
    settings: settingsReducer,
    statistics: statisticsReducer,
    card: cardManagementReducer
})
