import axios from "axios";


export const BASEURL = "https://hipay.pw/v1/";


export const Requester = axios.create({
    baseURL: BASEURL
});


export var AuthRequester = axios.create({
    baseURL: BASEURL,
    headers: {
        'Authorization': "Bearer " + localStorage.getItem("_access_token")
    }
})


export const intializeAuthRequester = () => {
    AuthRequester = axios.create({
        baseURL: BASEURL,
        headers: {
            'Authorization': "Bearer " + localStorage.getItem("_access_token")
        }
    })
}