import { StatisticsTypes } from "../globals/types";



let _initialState = {
    data: {},
    is_loading: false
}


export const statisticsReducer = (state = _initialState, action) => {
    switch (action.type) {
        case StatisticsTypes.UPDATE_STATE:
            return {
                ...state,
                data: action.data,
                is_loading: action.is_loading
            }
        default:
            return { ...state }
    }
}