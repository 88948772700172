import { LoginStates } from "../globals/types"



var initialState = {
    user: {},
    is_loading: false,
    is_login: false
}


export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case LoginStates.LOGIN_PROGRESS:
            return {
                ...state,
                data: action.user,
                user: action.user,
                is_loading: action.is_loading,
                is_logged_in: action.is_logged_in
            }
        case LoginStates.LOGIN_STATE:
            return {
                ...state,
                data: action.user,
                user: action.user,
                is_loading: action.is_loading,
                is_logged_in: action.is_logged_in
            }
        default:
            return { ...state }
    }
}
