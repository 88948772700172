import { message } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateGatewaySettings } from "../../../actions/settings"
import CardComponent from '../../../widgets/elements/card.component';
import { addNewCardPayment } from "../../../actions/card_management"
import "../styles/gateway.css"
const shetabIran = require("iran-shetab");


class GatewaySettings extends Component {

    constructor(props) {
        super(props)
        this.state = {
            business_name: '',
            redirect_url: '',
            card_number: null,
            card_exp_month: null,
            card_exp_year: null,
            validation: {
                is_valid: true
            }
        }
    }

    copyToClipBoard = (text) => {
        navigator.clipboard.writeText(text);
        message.success({
            content: 'کپی شد!'
        })
    }


    componentDidMount() {
        this.setState({
            business_name: this.props.vendor_info.business_name,
            redirect_url: this.props.vendor_info.redirect_url
        })
    }


    changePublicDetails = (e) => {
        let _form = document.getElementById("change-details-form")
        if (this.props.settings.is_loading) return;
        if (!_form.checkValidity()) {
            return;
        }
        e.preventDefault();
        this.props.updateGatewaySettings(this.state.business_name, this.state.redirect_url)
    }

    submitCardCreation = (first, second, third, fourth, expMonth, expYear) => {
        try {
            let _card = first + second + third + fourth;
            let _month = parseInt(expMonth);
            if (_month > 12) {
                return message.error({
                    content: "تاریخ انقضای کارت نادرست است"
                })
            }
            if (!shetabIran.isValid(_card)) {
                return message.error({
                    content: "شماره کارت نامعتبر است"
                })
            }
            this.props.addNewCardPayment(_card, expMonth, expYear)
        } catch (error) {
            message.error({
                content: "اطلاعات کارت نادرست است"
            })
        }
    }



    onCardUpdate = (first, second, third, fourth, expMonth, expYear) => {
        let _card = first + second + third + fourth;
        let _cardInfo = shetabIran.recognize(_card);
        let _isValid = shetabIran.isValid(_card)
        this.setState({
            validation: {
                ..._cardInfo,
                is_valid: _isValid
            }
        })
    }


    render() {
        return (
            <div className="gateway-setting">
                <div className="gateway-wrapper">
                    <h1 className="title-style">تنظیمات درگاه</h1>
                    <p className="title-low-sized">شما میتوانید اسم و لوگو کسب و کار خود را تغییر بدید</p>
                    <div className="sized-box-height-40"></div>
                    <div className="gateway-edit-wrapper">
                        <div className="circle-avatar" style={{
                            backgroundImage: `url('${this.props.vendor_info.business_logo}')`
                        }}></div>
                        <h1 className="title-style">{this.props.vendor_info.business_name}</h1>
                        <div className="sized-box-height-10"></div>
                        <form style={{ width: "100%" }} id="change-details-form">
                            <input required type="text" placeholder="اسم نمایه درکاه" onChange={(e) => this.state.business_name = e.target.value} defaultValue={this.state.business_name} className="input-style rtl-align"></input>
                            <div className="sized-box-height-10"></div>
                            <input required type="url" placeholder="آدرس بازگشتی به API" onChange={(e) => this.state.redirect_url = e.target.value} defaultValue={this.state.redirect_url} className="input-style rtl-align"></input>
                            <div className="sized-box-height-10"></div>
                            <button className="button-element" onClick={(e) => this.changePublicDetails(e)} disabled={this.props.settings.is_loading}>بروزرسانی اطلاعات</button>
                        </form>
                    </div>
                    <div className="sized-box-height-40"></div>
                    <div className="gateway-edit-wrapper">
                        <h1 className="title-style">کارت پرداخت</h1>
                        <p className="title-low-sized">برای انجام پرداخت ها لطفا شماره کارت که میخواهید پرداخت ها واریز شود را وارد کنید در غیر اینصورت مشتری های شما قادر به پرداخت نخواهند بود</p>
                        <div className="card-wrapper">
                            <div className="sized-box-height-20"></div>
                            <CardComponent isLoading={this.props.card.is_loading} onCardUpdate={this.onCardUpdate} validation={this.state.validation} withExtra={true} cardDone={this.submitCardCreation} query={this.props.vendor_info} />
                            <div className="sized-box-height-20"></div>
                        </div>
                    </div>
                    <div className="sized-box-height-40"></div>
                    <div className="gateway-edit-wrapper">
                        <h1 className="title-style">اطلاعات پیاده سازی درگاه</h1>
                        <p className="title-low-sized">اگر شما دانشی از برنامه نویسی ندارید لطفا اطلاعات این قسمت را با برنامه نویس به اشتراک بگذارید، درنظر داشته باشید که این اطلاعات باید به صورت محرمانه باشد</p>
                        <div className="sized-box-height-40"></div>
                        <div className="row-detail">
                            <div className="row-detail-compact">
                                <button className="text-button" onClick={() => this.copyToClipBoard(this.props.vendor_info.merchent_id)} >کپی کن!</button>
                                <div className="sized-box-width-10"></div>
                                <h1 className="title-low-sized bold key-wrapper" ><strong>{this.props.vendor_info.merchent_id}</strong></h1>
                            </div>
                            <h1 className="title-low-sized bold"><strong>API MERCHANT ID</strong></h1>
                        </div>
                        <div className="sized-box-height-20"></div>
                        <div className="row-detail">
                            <div className="row-detail-compact">
                                <button className="text-button" onClick={() => this.copyToClipBoard(this.props.vendor_info.api_key)}>کپی کن!</button>
                                <div className="sized-box-width-10"></div>
                                <h1 className="title-low-sized bold key-wrapper"><strong>{this.props.vendor_info.api_key}</strong></h1>
                            </div>
                            <h1 className="title-low-sized bold"><strong>API KEY</strong></h1>
                        </div>
                        <div className="sized-box-height-20"></div>
                    </div>
                    <div className="sized-box-height-40"></div>
                </div>
            </div>
        );
    }
}

const mapPropsToState = (props) => {
    return {
        vendor_info: props.login.user,
        settings: props.settings,
        card: props.card
    }
}

export default connect(mapPropsToState, { updateGatewaySettings, addNewCardPayment })(GatewaySettings);